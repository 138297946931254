













import { Faq } from '@/models';
import AlertModule from '@/store/vuex/aplicacao/AlertModule';
import { FindFaqUseCase } from '@/usecases';
import { Vue, Component, Prop} from 'vue-property-decorator'

@Component({
	components: {
		
	},
})

export default class TelaDeFaqDetalhada extends Vue {
@Prop({ type: String }) idFaq!: string

faq: Faq | null = null
findFaqUseCase = new FindFaqUseCase()
faqFormatada: any

created() {
	this.buscaFaq()
}



async buscaFaq() {
	try {
		const faqAtualizada = await this.findFaqUseCase.findById(this.idFaq) 
		this.faq = faqAtualizada
		
	} catch(error) {
		AlertModule.setError(error)
	}
}

get descricaoFormatada() {
	if(!this.faq) return ''
	const faqFormatada = this.faq.descricao.substring(1, this.faq.descricao.length - 1)
	return faqFormatada.replace(/[\\]/g, '');
}
}
